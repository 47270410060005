import { HttpParams, Filter, Pageable, Operator, Direction, Sorting, Condition, SortingArray } from './../models/http-params.model';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class APIService {


  api(): string {
    return environment.server;
  }

  makeHeaders(): any {
    const options = {
      Authorization: 'Bearer ' + localStorage.getItem('pereToken')
    };
    return options;
  }

  excludeInterceptorHeader(): any {
    const options = {
      'exclude-interceptor' : true
    };
    return options;
  }

  getById(id): HttpParams {
    const params: HttpParams = new HttpParams();
    let operator: Operator = new Operator();
    let filter: Filter = new Filter();
    params.operator = operator;
    filter.field = 'id';
    filter.value = id;
    params.operator.filter = [filter];
    return params;
  }

  getParams(page: number, size: number, paged?: boolean, unpaged?: boolean): HttpParams {
    const params: HttpParams = new HttpParams();
    let operator: Operator = new Operator();
    let pageable: Pageable = new Pageable();
    params.pageable = pageable;
    params.operator = operator;
    params.operator.filter = [];
    params.pageable.pageNumber = page;
    params.pageable.pageSize = size;
    if(paged) {params.pageable.paged = paged} else {delete params.pageable.paged};
    if(unpaged) {params.pageable.unpaged = unpaged} else {delete params.pageable.unpaged};
    //params.pageable.sort.direction = Direction.DESC;
    //params.pageable.sort.properties = [sortBy];
    return params;
  }

  getParamsForPaymentList(page: number, size: number, direction = 'DESC', sortBy = SortingArray): HttpParams {
    const params: HttpParams = new HttpParams();
    let operator: Operator = new Operator();
    let pageable: Pageable = new Pageable();
    params.pageable = pageable;
    params.operator = operator;
    params.operator.filter = [];
    params.pageable.pageNumber = page;
    params.pageable.pageSize = size;
    params.pageable.sort.direction = direction === 'DESC' ? Direction.DESC : Direction.ASC;
    params.pageable.sort.properties = sortBy;
    return params;
  }

  getParamsForRestaurantsList(page: number, size: number, direction = 'DESC', sortBy = SortingArray): HttpParams {
    const params: HttpParams = new HttpParams();
    let operator: Operator = new Operator();
    let pageable: Pageable = new Pageable();
    params.pageable = pageable;
    params.operator = operator;
    params.operator.filter = [];
    params.pageable.pageNumber = page;
    params.pageable.pageSize = size;
    params.pageable.sort.direction = direction === 'DESC' ? Direction.DESC : Direction.ASC;
    params.pageable.sort.properties = sortBy;
    return params;
  }

  addFilter(params: HttpParams, filters: [Filter], operator: Operator): HttpParams {
    params.operator = operator;
    params.operator.filter = filters;
    return params;
  }

  filterBetweenDates(params: HttpParams, fromDate, toDate, field, sorting?: Sorting): HttpParams {
    if (fromDate != null) {
      const startFilter: Filter = new Filter();
      startFilter.condition = Condition.GREATER_THAN_EQUAL_DATE;
      startFilter.value = fromDate;
      startFilter.field = field;
      params.operator.filter.push(startFilter);
    }
    if (toDate != null) {
      const endFilter: Filter = new Filter();
      endFilter.condition = Condition.LESS_THAN_EQUAL_DATE;
      endFilter.value = toDate;
      endFilter.field = field;
      params.operator.filter.push(endFilter);
    }

    if (params.pageable) {
      let sort = new Sorting();
      params.pageable.sort = sort;
    }
    if (sorting == null) {
      params.pageable.sort.direction = Direction.DESC;
      params.pageable.sort.properties = [field];
    } else {
      params.pageable.sort = sorting;
    }
    return params;
  }

}
