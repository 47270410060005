import { APIService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpParams, Filter, Condition } from '../models/http-params.model';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Brand } from '../models/brand.model';
import { Subject } from 'rxjs';
import { map } from "rxjs/operators";

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class PublicService extends APIService  {

  constructor(private http: HttpClient) {
    super();
  }

  canActivate(route: ActivatedRouteSnapshot) {
    // if (!route.queryParams.edit) {
    //   localStorage.removeItem('pereToken');
    //   localStorage.removeItem('pereUser');
    // }

    let id;
    if (route.queryParams.id) {
      id = route.queryParams.id
    } else if (route.firstChild.params.id) {
      id = route.firstChild.params.id
    } else if (localStorage.getItem('perePublic')) {
      id = JSON.parse(localStorage.getItem('perePublic')).userExtra.company.id;
    } else {
      return true
    }

    return this.getHotelBrand(id).pipe(map((e: any) => {
      if (e) {
        localStorage.setItem('perePublic', JSON.stringify(e));
        this.applyBrandTheme(e.primaryColor, e.secondaryColor)
        return true;
      }
    }));
  }

  private footerChangeDetection = new Subject<any>();
  getFooterCD = this.footerChangeDetection.asObservable();
  updateFooter(footerChangeDetection) {
    this.footerChangeDetection.next(footerChangeDetection);
  }

  private chooseAnotherDate = new Subject<boolean>();
  getChooseAD = this.chooseAnotherDate.asObservable();
  updateChooseAD(chooseAnotherDate) {
    this.chooseAnotherDate.next(chooseAnotherDate);
  }

  isPublic() {
    if (window.location.href.indexOf("public") > -1) {
      return true;
    } else {
      return false;
    }
  }

  getFiltersV2(type, pageId = 0, pageSize = 5, countryId, hotelId) {
    return this.http.get(this.api() + 'api/public/tags/' + type + '/sorted?pageId=' + pageId + '&pageSize=' + pageSize + '&countryId=' + countryId + '&hotelId=' + hotelId);
  }

  getHotelBrand(id) {
    return this.http.get(`${this.api()}api/public/bookings/brand/${id}`);
  }

  getAvailabilityV2(params) {
    let json: HttpParams = this.getParamsForRestaurantsList(params.page, params.size, params.direction, params.sorting);;
    let pax: Filter = new Filter();
    pax.condition = Condition.EQUAL;
    pax.field = 'pax';
    pax.value = params.pax;
    let lunchOrDinner: Filter = new Filter();
    lunchOrDinner.condition = Condition.EQUAL;
    lunchOrDinner.field = 'lunchOrDinner';
    lunchOrDinner.value = params.lunchOrDinner;
    let reservationDate: Filter = new Filter();
    reservationDate.condition = Condition.EQUAL;
    reservationDate.field = 'reservationDate';
    reservationDate.value = params.reservationDate;
    json.operator.filter.push(pax);
    json.operator.filter.push(lunchOrDinner);
    json.operator.filter.push(reservationDate);
    if (params.id) {
      let brandId: Filter = new Filter();
      brandId.condition = Condition.EQUAL;
      brandId.field = 'id';
      brandId.value = params.id;
      json.operator.filter.push(brandId);
    }
    if (params.city) {
      let cityFilter: Filter = new Filter();
      cityFilter.condition = Condition.EQUAL;
      cityFilter.field = 'city';
      cityFilter.value = params.city;
      json.operator.filter.push(cityFilter);
    }
    if (params.area) {
      let areaFilter: Filter = new Filter();
      areaFilter.condition = Condition.EQUAL;
      areaFilter.field = 'area';
      areaFilter.value = params.area;
      json.operator.filter.push(areaFilter);
    }
    if (params.visibility) {
      let visibility: Filter = new Filter();
      visibility.condition = Condition.EQUAL;
      visibility.field = 'visibility';
      visibility.value = params.visibility;
      json.operator.filter.push(visibility);
    }
    if (params.fetches && params.fetches.length > 0) {
      json.fetches = params.fetches
    } else {
      json.fetches = ['availability'];
    }
    if (params.coordinate) {
      let coordinate: Filter = new Filter();
      coordinate.condition = Condition.BETWEEN;
      coordinate.field = 'location';
      coordinate.value = params.location;
      json.operator.filter.push(coordinate)
    }

    if (params.cuisineList || params.categoryList) {
      let tags: Filter = new Filter();
      tags.condition = Condition.IN;
      tags.field = 'tags';
      tags.value = [...params.categoryList, ...params.cuisineList];
      if (tags.value.length > 0) json.operator.filter.push(tags)
    }
    let avaragePrice: Filter = new Filter();
    avaragePrice.condition = Condition.BETWEEN;
    avaragePrice.field = 'avaragePrice';
    avaragePrice.value = params.priceList;
    let commission: Filter = new Filter();
    commission.condition = Condition.BETWEEN;
    commission.field = 'commission';
    commission.value = params.commission;
    if (avaragePrice.value) json.operator.filter.push(avaragePrice);
    if (commission.value) json.operator.filter.push(commission);

    return this.http.post(this.api() + 'api/v2/company/public/dashboard', json)
  }

  getAvailabilityById(params) {
    let json: HttpParams = new HttpParams();
    let pax: Filter = new Filter();
    pax.condition = Condition.EQUAL;
    pax.field = 'pax';
    pax.value = params.pax;
    let lunchOrDinner: Filter = new Filter();
    lunchOrDinner.condition = Condition.EQUAL;
    lunchOrDinner.field = 'lunchOrDinner';
    lunchOrDinner.value = params.lunchOrDinner;
    let reservationDate: Filter = new Filter();
    reservationDate.condition = Condition.EQUAL;
    reservationDate.field = 'reservationDate';
    reservationDate.value = params.reservationDate;
    let restuarantId: Filter = new Filter();
    restuarantId.condition = Condition.EQUAL;
    restuarantId.field = 'restaurantsId';
    restuarantId.value = [params.restaurantId];
    let brandId: Filter = new Filter();
    brandId.condition = Condition.EQUAL;
    brandId.field = 'id';
    brandId.value = params.id;
    json.operator.filter.push(pax);
    json.operator.filter.push(lunchOrDinner);
    json.operator.filter.push(reservationDate);
    json.operator.filter.push(restuarantId);
    json.operator.filter.push(brandId);
    return this.http.post(this.api() + 'api/v2/company/public/availability', json, { headers: super.makeHeaders() })
  }

  createBooking(params) {
    return this.http.post(this.api() + 'api/public/bookings/', params, { headers: super.makeHeaders() });
  }


  applyBrandTheme(primaryColor, secondaryColor) {
    $('body').attr('id', 'theming');

    let css = `
          #theming button.mat-primary {background: ${primaryColor}}
          #theming button.mat-primary {color: #fff}
          #theming button.mat-accent {background: ${secondaryColor}}
          #theming .social-links a {color: ${primaryColor}}
          #theming .loading-placeholder .loader {background: linear-gradient(to right, ${primaryColor} 10 %, rgba(255, 255, 255, 0) 62 %)}
          #theming .inner-slot {background: ${primaryColor}}
          #theming .slick-active li button.selected, #theming .slick-active li button:hover {background: ${primaryColor} !important}
          #theming .search-landing {border-color: ${secondaryColor}}
          #theming .btn-underlined {border-color: ${primaryColor}}
          #theming app-offline-requests-setting .mat-form-field-appearance-legacy .mat-form-field-underline {background-color: ${primaryColor} !important}
          #theming app-offline-requests-setting .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {background-color: ${primaryColor} !important}
          #theming .mat-radio-outer-circle {border-color: ${primaryColor}}
          #theming .mat-radio-inner-circle {background-color: ${primaryColor}}
          #theming .mat-expansion-panel-header {border-color: ${primaryColor}}
          #theming .slider-handle {background-color: ${primaryColor} }
          #theming .slider-selection {background-color: ${primaryColor}}
          #theming .border-bottom-pere {border-color: ${secondaryColor}}
          #theming .details-box {border-color: ${primaryColor}}
          #theming .arrow-ico {fill:${secondaryColor}}
          #theming .mat-dialog-container {border: 1px solid ${primaryColor}}
          #theming .restaurant-card2:hover {box-shadow: 0px 4px 8px 0px ${primaryColor}}
          #theming .must-try-list div a:hover {box-shadow: 0px 4px 8px 0px ${primaryColor}}
          #theming .cats-list a:hover {box-shadow: 0px 4px 8px 0px ${primaryColor}}
          #theming .mat-form-field-ripple {background-color: #2E2A26}
          #theming .mat-mdc-select-arrow {display: none !important}
          #theming .mat-form-field.mat-form-field-invalid .mat-form-field-ripple {background-color: #f44336}
          #theming .mat-calendar-body-selected {background-color: ${primaryColor}}
          #theming .mat-expansion-indicator {display: none !important}
          #theming .time-slots .carousel li button {background: #E9E9E9}
          #theming .chosen-for-you .loading-placeholder .loader  {background: linear-gradient(to right, ${primaryColor} 10%, rgba(255, 255, 255, 0) 62%);}
          #theming .mat-checkbox-checkmark-path {stroke: ${primaryColor} !important}
          #theming  app-breadcrumb .arrow-ico {fill: ${secondaryColor} !important}
          #theming  app-hotel-room-paper .content {box-shadow: 0px 4px 8px 0px ${primaryColor}}
          #theming  app-filter-icon svg g {fill:  ${primaryColor} !important}
          #theming  app-view-icon svg g {fill:  ${primaryColor} !important}
          #theming  .search-criteria.inline {border: 1px solid ${primaryColor} !important}
          #theming  app-brand-header .cover:not(.inner-height) {background-color: ${primaryColor} !important}
          #theming  app-brand-header .mobile-imgs ul li>div {background-color: ${primaryColor} !important}
          #theming  app-brand-header .mobile-imgs .welcome-msg {background-color: ${primaryColor} !important}
          #theming .mat-focused .mat-form-field-label {color: #2E2A26}`,
          

      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    head.appendChild(style);
    style.appendChild(document.createTextNode(css));

  }

  getRestuarantData(hotelId, restaurantID) {
    return this.http.get(this.api() + 'api/public/bookings/company/' + hotelId + '/' + restaurantID, { headers: super.makeHeaders() });
  }

  updateBrand(brand: Brand) {
    return this.http.put(this.api() + `api/bookings/brand`, brand, { headers: this.makeHeaders() });
  }

  // get top 10 of booked per hotel
  getTopTenRestaurants(hotelId, bookingStatus = 'COMMISSIONED') {
    return this.http.get(this.api() + 'api/public/bookings/companies/top/booked?id=' + hotelId + '&bookingstatus=' + bookingStatus, { headers: super.makeHeaders() });
  }

  // // search in brands list
  // searchBrandsList(params) {
  //   return this.http.get(this.api() + 'api/public/bookings/companies/hotel/page/like', { params: params });
  // }

  // get brands list
  getBrandsList(params) {
    let json: HttpParams = this.getParams(params.page, params.size);
    if (params.countryID) {
      let countryID: Filter = new Filter();
      countryID.condition = Condition.EQUAL;
      countryID.field = 'country.id';
      countryID.value = params.countryID;
      json.operator.filter.push(countryID);
    }
    if (params.name) {
      let name: Filter = new Filter();
      name.condition = Condition.LIKE;
      name.field = 'name';
      name.value = params.name;
      json.operator.filter.push(name);
    }

    return this.http.post(this.api() + 'api/public/brands', json);
  }

  getCountries() {
    return this.http.post(this.api() + 'api/public/countries', {});
  }

  // usually this request made from miss restuarant
  submitNewCompany(req) {
    return this.http.post(this.api() + 'api/v2/company/request-company', req);
  }

  // this called when opening the persuade page from email
  getNewCompanyReq(id) {
    return this.http.get(this.api() + `api/public/company/${id}`);
  }

  updateNewCompanyInfo(companyId, req) {
    return this.http.post(this.api() + `api/v2/company/public/accept-request?companyId=${companyId}`, req);
  }

  // updateNewCompanyInfo(companyId, req) {
  //   return this.http.post(this.api() + `api/public/accept-set-main-user/${companyId}`, req);
  // }

  getHotelsNearby(id, accountType) {
    return this.http.get(this.api() + `api/public/nearBy/companies/${id}/${accountType}`);
  }

  getSupportedPlan(countryID) {
    return this.http.get(this.api() + `api/v2/settings/public/group/PRICE_PLAN/${countryID}/COUNTRY`);
  }

  submitBookingRquest(req) {
    return this.http.post(this.api() + 'api/public/bookings/request', req);
  }

  getSettingsGroup(settingTypeId: number, type: string, attributeGroup: string) {
    return this.http.get(this.api() + `api/v2/settings/public/group/${attributeGroup}/${settingTypeId}/${type}`);
  }

  getBookingDetails(bookingId) {
    return this.http.get(this.api() + `api/public/bookings/${bookingId}`);
  }

  validateServicePayment(voucherCode) {
    return this.http.post(this.api() + `api/public/bookings/validate/voucher/${voucherCode}`, null);
  }
  validateServicePaymentV2(voucherCode) {
    return this.http.post(this.api() + `api/public/bookings/voucher/${voucherCode}`, null);
  }
  redeemVoucher(voucherCode) {
    return this.http.post(this.api() + `api/public/bookings/redeem/voucher/${voucherCode}`, null);
  }

  changeBookingStatus(id: number, status: string, email: string) {
    return this.http.post(this.api() + `api/public/bookings/${id}/${status}/email/${email}`, null, { headers: this.makeHeaders() });
  }

  getReviews(type: string, companyId: number) {
    if (type === 'COMPANY') {
      return this.http.get(this.api() + `api/v2/user-company-review/public/company-average-rating/${companyId}`);
    } else if (type === 'USER') {
      return this.http.get(this.api() + `api/v2/user-company-review/${companyId}`, { headers: this.makeHeaders() });
    }
  }

  getAllReviews(companyId: number) {
    return this.http.get(this.api() + `api/v2/user-company-review/public/find-all/${companyId}`);
  }

  updateReviews(companyId: number, rate) {
    return this.http.post(this.api() + `api/v2/user-company-review/${companyId}`, rate, { headers: this.makeHeaders() });
  }

  updateReply(reply) {
    return this.http.post(this.api() + `api/v2/review-reply`, reply, { headers: this.makeHeaders() });
  }

  delReply(replyId) {
    return this.http.delete(this.api() + `api/v2/review-reply/${replyId}`, { headers: this.makeHeaders() });
  }
  getAnotherAreasWithAreaId(areaId) {
    return this.http.get(this.api() + `api/public/bookings/area/with-in/${areaId}`, { headers: this.makeHeaders() });
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });
  }

  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  getVoucherRestaurants(countryId) {
    return this.http.get(this.api() + `api/public/bookings/voucher-restaurant/${countryId}`, { headers: this.makeHeaders() });
  }

  createPendingBooking(booking) {
    return this.http.post(this.api() + `api/public/pending-bookings`, booking);
  }
  commitPendingBooking(booking) {
    return this.http.post(this.api() + `api/public/commit-bookings`, booking);
  }

  

}
