import appPackage from '../../package.json';

export const environment = {
  production: true,
  server: 'https://prod.platform.pere.com:443/',
  VERSION: appPackage.version,
  mixpanel: 'a99078ab4fa31532c958aacb4d8bbec1',
  stripeKey: 'pk_live_557OnAIF9S8Gxc53gKPNOWus',
  googleAPIKey: 'AIzaSyCp6cXj2ih-UvqMuoFyG39IxEoqKYcS_xA'
};
