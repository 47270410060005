import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ROLES, ROLES_SUPPLIER_RESTAURANT, ROLES_HOTEL } from '../models/roles.model';
import { environment } from 'src/environments/environment';
import { Restaurant } from '../models/restaurant.model';

@Injectable({
    providedIn: 'root'
})
export class UserPrivilegesService  {
    roleName: string;
    isProduction: boolean;
    private userRole = new BehaviorSubject<string>('guest');
    getUserRole = this.userRole.asObservable();
    storeUserRole(userRole) {
        this.userRole.next(userRole);
    }

    constructor(private router: Router, private translate: TranslateService) { }

    isHotel(role: string) {
        if (role === 'hotelUser' || role === 'hotelAdmin' || role === 'hotelGroupAdmin') {
            return true;
        } else {
            return false;
        }
    }

    canActivate() {
        this.isProduction = environment.production;
        let user = JSON.parse(localStorage.getItem('pereUser'));
        let isPublic = window.location.href.indexOf("public")
        if (user === null) {
            if (isPublic) {
                localStorage.setItem('distanceUnit', localStorage.getItem('perePublic') && (JSON.parse(localStorage.getItem('perePublic')).userExtra.country.countryCode === 'US' || JSON.parse(localStorage.getItem('perePublic')).userExtra.country.countryCode === 'GB') ? 'mi' : 'km');
                this.getPrivilege([]);
            } else {
                this.router.navigate(['/auth/login']);
            }
        } else {
            localStorage.setItem('distanceUnit', JSON.parse(localStorage.getItem('pereUser')).userExtra.country.countryCode === 'US' || JSON.parse(localStorage.getItem('pereUser')).userExtra.country.countryCode === 'GB' ? 'mi' : 'km');
            this.getPrivilege(user.authorities);
        }
        return true;
    }

    setFeatures(feature: String) {

        switch (feature) {
            case 'insights':
                return this.isProduction ? false : true;
            case 'events':
                return this.isProduction ? true : true;
            case 'perks':
                return this.isProduction ? false : true;
            case 'automatedMsgs':
                return this.isProduction ? false : true;
        }
    }

    getPrivilege(arr = []) {
        if (arr.includes(ROLES.ADMIN)) {
            this.storeUserRole('superAdmin');
            return 'superAdmin';
        } else if (arr.includes(ROLES.API_EXTERNAL_PARTNER)) {
            this.storeUserRole('apiExternalPartner');
            return 'apiExternalPartner';
        }
        else if (arr.includes(ROLES.ROLE_GROUP_ADMIN_HOTEL)) {
            if (arr.includes(ROLES.ROLE_GROUP_ADMIN)) {
                this.storeUserRole('superGroupAdmin');
                return 'superGroupAdmin';
            } else {
                this.storeUserRole('hotelGroupAdmin');
                return 'hotelGroupAdmin';
            }
        } else if (arr.includes(ROLES.ROLE_GROUP_ADMIN)) {
            this.storeUserRole('groupAdmin');
            return 'groupAdmin';
        } else if (arr.includes(ROLES.SALES_DIRECTOR)) {
            this.storeUserRole('salesDirector');
            return 'salesDirector';
        } else if (arr.includes(ROLES.ROLE_RECOMMENDER_ADMIN) && !arr.includes(ROLES.API_EXTERNAL_PARTNER)) {
            this.storeUserRole('hotelAdmin');
            return 'hotelAdmin';
        } else if (arr.includes(ROLES.ROLE_SUPPLIER_ADMIN)) {
            this.storeUserRole('restaurantAdmin');
            return 'restaurantAdmin';
        } else if (arr.includes(ROLES.ROLE_RECOMMENDER_HOTEL)) {
            this.storeUserRole('hotelUser');
            return 'hotelUser';
        } else if (arr.includes(ROLES.ROLE_SUPPLIER_RESTAURANT)) {
            this.storeUserRole('restaurantUser');
            return 'restaurantUser';
        } else if (arr.includes(ROLES.FINANCE)) {
            this.storeUserRole('finance');
            return 'finance';
        } else if (arr.includes(ROLES.RESTAURANT_FINANCE)) {
            this.storeUserRole('restaurantFinance');
            return 'restaurantFinance';
        } else {
            this.storeUserRole('guest');
            return 'guest';
        }
    }

    // getting the userType without storing it inside the observable
    getPlainPrivilege(arr = []) {
        if (arr.includes(ROLES.ADMIN)) {
            return 'superAdmin';
        } else if (arr.includes(ROLES.ROLE_GROUP_ADMIN_HOTEL)) {
            if (arr.includes(ROLES.ROLE_GROUP_ADMIN)) {
                return 'superGroupAdmin';
            } else {
                return 'hotelGroupAdmin';
            }
        } else if (arr.includes(ROLES.ROLE_GROUP_ADMIN)) {
            return 'groupAdmin';
        } else if (arr.includes(ROLES.SALES_DIRECTOR)) {
            return 'salesDirector';
        } else if (arr.includes(ROLES.ROLE_RECOMMENDER_ADMIN) && !arr.includes(ROLES.API_EXTERNAL_PARTNER)) {
            return 'hotelAdmin';
        } else if (arr.includes(ROLES.API_EXTERNAL_PARTNER)) {
            this.storeUserRole('apiExternalPartner');
            return 'apiExternalPartner';
        } else if (arr.includes(ROLES.ROLE_SUPPLIER_ADMIN)) {
            return 'restaurantAdmin';
        } else if (arr.includes(ROLES.ROLE_RECOMMENDER_HOTEL)) {
            return 'hotelUser';
        } else if (arr.includes(ROLES.ROLE_SUPPLIER_RESTAURANT)) {
            return 'restaurantUser';
        } else if (arr.includes(ROLES.FINANCE)) {
            return 'finance';
        } else if (arr.includes(ROLES.RESTAURANT_FINANCE)) {
            return 'companyFinance';
        } else {
            return 'guest';
        }
    }

    isAffiliate(arr = []) {
        let currentUser = JSON.parse(localStorage.getItem('pereUser'));
        if (arr.length <= 0) {
            arr = currentUser ? currentUser.authorities : [];
        }
        if (arr.includes('ROLE_AFFILIATE')) {
            return true;
        } else { return false; }
    }

    getUserType(arr = []) {
        if (arr.includes(ROLES.ADMIN)) {
            this.roleName = this.translate.instant('roleName.superAdmin');
            return this.roleName;
        } else if (arr.includes(ROLES.ROLE_GROUP_ADMIN_HOTEL)) {
            this.roleName = 'hotelGroupAdmin';
            return this.roleName;
        } else if (arr.includes(ROLES.SALES_DIRECTOR)) {
            this.roleName = 'salesDirector';
            return this.roleName;
        } else if (arr.includes(ROLES.ROLE_GROUP_ADMIN)) {
            this.roleName = this.translate.instant('roleName.groupAdmin');
            return this.roleName;
        } else if (arr.includes(ROLES.ROLE_RECOMMENDER_ADMIN) && !arr.includes(ROLES.API_EXTERNAL_PARTNER)) {
            this.roleName = this.translate.instant('roleName.hotelAdmin');
            return this.roleName;
        } else if (arr.includes(ROLES.API_EXTERNAL_PARTNER)) {
            this.roleName = 'apiExternalPartner'
            return this.roleName;
        } else if (arr.includes(ROLES.ROLE_SUPPLIER_ADMIN)) {
            this.roleName = this.translate.instant('roleName.restaurantAdmin');
            return this.roleName;
        } else if (arr.includes(ROLES.ROLE_RECOMMENDER_HOTEL)) {
            this.roleName = this.translate.instant('roleName.hotelUser');
            return this.roleName;
        } else if (arr.includes(ROLES.ROLE_SUPPLIER_RESTAURANT)) {
            this.roleName = this.translate.instant('roleName.restaurantUser');
            return this.roleName;
        } else if (arr.includes(ROLES.RESTAURANT_FINANCE)) {
            this.roleName = this.translate.instant('roleName.companyFinance');
            return this.roleName;
        } else if (arr.includes('ROLE_GUEST') || arr.includes('ROLE_PUBLIC_BOOKING_USER')) {
            this.roleName = this.translate.instant('roleName.publicUser');
            return this.roleName;
        } else if (arr.includes(ROLES.FINANCE)) {
            this.roleName = 'finance';
            return this.roleName;
        } else {
            this.roleName = this.translate.instant('roleName.unknown');
            return this.roleName;
        }
    }

    getCompanyType(role: string) {
        if (role === 'hotelUser' || role === 'hotelAdmin' || role === 'apiExternalPartner') {
            return 'Hotel';
        } else if (role === 'restaurantUser' || role === 'restaurantAdmin') {
            return 'Restaurant';
        } else if (role === 'groupAdmin') {
            return 'Restaurant Group'
        } else if (role === 'guest') {
            return 'Public'
        } else if (role === 'hotelGroupAdmin') {
            return 'Hotel Group Admin'
        } else if (role === 'salesDirector') {
            return 'Sales director'
        } else {
            return 'Admin';
        }
    }

    getAuthoritiesArray(isAdmin, type) {

        switch (type) {
            case 'Restaurant': case 'Restaurant Group': case 'Service':
                if (isAdmin)
                    return ROLES_SUPPLIER_RESTAURANT
                else
                    return ['ROLE_SUPPLIER_RESTAURANT']
            case 'Hotel': case 'Hotel Group Admin':
                if (isAdmin)
                    return ROLES_HOTEL
                else
                    return ['ROLE_RECOMMENDER_HOTEL']
            case 'Sales director':
                return ['SALES_DIRECTOR']
        }
    }

    showPayment(type, role, affiliateType) {
        if (type === 'user') {
            if (role === 'hotelAdmin') {
                if (affiliateType === 'BUSINESS_MANAGEMENT') {
                    return false
                } else { // if business direct
                    return true
                }
            } else if (role === 'hotelUser') {
                if (affiliateType === 'BUSINESS_MANAGEMENT') {
                    return false
                } else { // if business direct
                    return true
                }
            } else if (role === 'superAdmin') {
                return true
            } else {
                return false
            }
        } else if (type === 'company') {
            if (role === 'hotelAdmin' || role === 'apiExternalPartner') {
                if (affiliateType === 'BUSINESS_MANAGEMENT') {
                    return true
                } else { // if business direct
                    return false
                }
            } else if (role === 'hotelUser') {
                if (affiliateType === 'BUSINESS_MANAGEMENT') {
                    return false
                } else { // if business direct
                    return false
                }
            } else if (role === 'superAdmin') {
                return true
            } else if (role === 'restaurantAdmin') {
                return true
            } else {
                return false
            }
        }
    }


    public isPayingSupplier(company: Restaurant) {
        // subscriptionType: "PAYING_SUPPLIER"
        // subscriptionType: "Subscription2"
        // subscriptionType: "MARKETING_SUPPLIER"
        // console.log(company)

        if (company.accountType === 'PERE') {
            return true
        }

        let subscriptionType;
        if (!company.companyLogs || company.companyLogs.length === 0) {
            subscriptionType = 'MARKETING'
        } else if (company.companyLogs[0].subscriptionType === 'MARKETING_SUPPLIER' || company.companyLogs[0].subscriptionType === 'PAYING_SUPPLIER') {
            subscriptionType = 'MARKETING'
        } else {
            subscriptionType = 'PAYING'
        }
        return subscriptionType === 'PAYING';
    }


}
