import { registerLocaleData } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PublicService } from './services/public.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(translate: TranslateService, private publicService: PublicService) {
    translate.setDefaultLang(localStorage.getItem('selectedLang') || 'en');
  }

  ngOnInit() {
    this.publicService.getCountries().subscribe((countries: any) => {
      const co = countries.content;
      co.forEach((country) => {
        const langArr = country.languageCode.split('_');
        this.localeInitializer(langArr[0], country.countryCode);
      });
    });
  }

  localeInitializer(localeId: string, localeString): Promise<any> {
    if (environment.server === 'http://localhost:4200/') {
      return import(
        /* webpackInclude: /(da|en|se|fr|nb|sv|nl)\.js$/ */
        `node_modules/@angular/common/locales/global/${localeId}.js`
      ).then(locale => {
        localeString = localeString === 'FR' ? 'en' : localeString;
        registerLocaleData(locale.default, localeString)
      });
    } else {
      return import(
        /* webpackInclude: /(da|sv|en|en|fr|en|en|en)\.js$/ */
        `node_modules/@angular/common/locales/global/${localeId}.js`
      ).then(locale => {
        localeString = localeString === 'FR' ? 'en' : localeString;
        registerLocaleData(locale.default, localeString)
      });
    }
  }
}
