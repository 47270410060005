export class HttpParams {
  operator: Operator = new Operator();
  pageable: Pageable = new Pageable();
  fetches: any[];
  params: any;
  language: string;
  required: boolean;

  public static from(
    filter: Filter[] = [],
    pageNumber: number = 0,
    pageSize: number = 10
  ) {
    const httpParams = new HttpParams();
    httpParams.operator.filter = filter;
    httpParams.pageable.pageNumber = pageNumber;
    httpParams.pageable.pageSize = pageSize;
    return httpParams;
  }
}

export class FunCall extends HttpParams {
  fun: Fun;
  path: string;
  result: number;
}

export enum Fun {
  SUM = 'SUM',
  MAX = 'MAX',
  MIN = 'MIN',
  COUNT = 'COUNT',
}

export class Operator {
  filter: Filter[] = [];
  operators: Operator[] = [];
  operatorType: OperatorType = OperatorType.AND;
}

export enum OperatorType {
  AND = 'AND',
  OR = 'OR',
}

export class Filter {
  condition: string;
  value: any;
  field: string;

  static build(field: string, condition: Condition, value: any): Filter {
    const filter = new Filter();
    filter.field = field;
    filter.condition = condition;
    filter.value = value;
    return filter;
  }

  static from(
    field: string,
    value: any,
    condition: Condition = Condition.EQUAL
  ): Filter {
    const filter = new Filter();
    filter.field = field;
    filter.condition = condition;
    filter.value = value;
    return filter;
  }
}

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
}

export class Sorting {
  direction: Direction = Direction.DESC;
  properties: string[];
}

export class Pageable {
  pageNumber: number;
  pageSize: number;
  paged?: boolean;
  unpaged?: boolean;
  offset?: number;
  sort: Sorting = new Sorting();
}

export enum Condition {
  MATCH = 'MATCH',
  NOT_MATCH = 'NOT_MATCH',
  LIKE = 'LIKE',
  NOT_LIKE = 'NOT_LIKE',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  EQUAL_DATE = 'EQUAL_DATE',
  NOT_EQUAL_DATE = 'NOT_EQUAL_DATE',
  GREATER_THAN_DATE = 'GREATER_THAN_DATE',
  GREATER_THAN_EQUAL_DATE = 'GREATER_THAN_EQUAL_DATE',
  LESS_THAN_DATE = 'LESS_THAN_DATE',
  LESS_THAN_EQUAL_DATE = 'LESS_THAN_EQUAL_DATE',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  MEMBER = 'MEMBER',
  NOT_MEMBER = 'NOT_MEMBER',
  IS_NULL = 'IS_NULL',
  BETWEEN = 'BETWEEN',
  IS_NOT_NULL = 'IS_NOT_NULL',
  UN_ACCENT_LIKE = 'UN_ACCENT_LIKE'
}

export const SortingArray = [
  'commission',
  'favorite',
  'priority',
  'bookings',
  'd.distance',
  'cd.avarage_price',
  'c.name',
];
export const SortingArrayPublic = [
  'favorite',
  'commission',
  'priority',
  'bookings',
  'd.distance',
  'cd.avarage_price',
  'c.name',
];

// EQUAL, NOT_EQUAL, LIKE for strings

// LESS_THAN, LESS_THAN_EQUAL, GREATER_THAN, GREATER_THAN_EQUAL for numbers

// class nonSense {
//     "operator": {
//         "filter": [
//             {
//                 "condition": "EQUAL",
//                 "field": "userExtra.company.id",
//                 "value": 226659
//             }
//         ];
//         "operatorType": "AND";
//         "operators": [
//             {
//                 "filter": [
//                     {
//                         "condition": "LIKE",
//                         "field": "firstName",
//                         "value": "admin"
//                     },
//                     {
//                         "condition": "LIKE",
//                         "field": "lastName",
//                         "value": "admin"
//                     }
//                 ],
//                 "operatorType": "OR"
//             }
//         ]
//     };
//     "pageable": {
//         "pageNumber": 0,
//         "pageSize": 10
//     }
// }
