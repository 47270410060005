export const ROLES = {
    ADMIN: 'ROLE_ADMIN', // PERE SUPER ADMIN
    USER: 'ROLE_USER', // READ ONLY USER

    FINANCE: 'ROLE_ADMIN_FINANCE',
    API_EXTERNAL_PARTNER: 'ROLE_API_EXTERNAL_PARTNER',

    ROLE_RECOMMENDER_ADMIN: 'ROLE_RECOMMENDER_ADMIN', // ADMIN FOR RECOMMENDERS
    ROLE_RECOMMENDER_HOTEL: 'ROLE_RECOMMENDER_HOTEL', // HOTEL TYPE
    ROLE_RECOMMENDER_SOLO: 'ROLE_RECOMMENDER_SOLO', // SOLO TYPE

    ROLE_SUPPLIER_ADMIN: 'ROLE_SUPPLIER_ADMIN', // ADMIN FOR SUPPLIER
    ROLE_SUPPLIER_RESTAURANT: 'ROLE_SUPPLIER_RESTAURANT', // RESTAURANT SUPPPLIER TYPE
    ROLE_SUPPLIER_TRANSPORT: 'ROLE_SUPPLIER_TRANSPORT', // TRANSPORT SUPPPLIER TYPE
    ROLE_SUPPLIER_EVENT: 'ROLE_SUPPLIER_EVENT', // EVENT SUPPPLIER TYPE

    ROLE_GROUP_ADMIN: 'ROLE_GROUP_ADMIN',

    ROLE_GROUP_ADMIN_HOTEL: 'ROLE_GROUP_ADMIN_HOTEL',

    ROLE_AFFILIATE: 'ROLE_AFFILIATE',

    SALES_DIRECTOR: 'SALES_DIRECTOR',

    RESTAURANT_FINANCE: 'RESTAURANT_FINANCE'
};

export const ROLE_AFFILIATE_ADMIN = [ROLES.ROLE_RECOMMENDER_HOTEL, ROLES.ROLE_RECOMMENDER_ADMIN, ROLES.ROLE_AFFILIATE];
export const ROLE_AFFILIATE_USER = [ROLES.ROLE_RECOMMENDER_HOTEL, ROLES.ROLE_AFFILIATE];

// tslint:disable-next-line:max-line-length
export const ROLES_ALL = [ROLES.ADMIN, ROLES.USER, ROLES.ROLE_RECOMMENDER_ADMIN, ROLES.ROLE_RECOMMENDER_HOTEL, ROLES.ROLE_RECOMMENDER_SOLO, ROLES.ROLE_SUPPLIER_ADMIN, ROLES.ROLE_SUPPLIER_ADMIN, ROLES.ROLE_SUPPLIER_EVENT, ROLES.ROLE_SUPPLIER_RESTAURANT, ROLES.ROLE_SUPPLIER_TRANSPORT];

export const ROLES_HOTEL = [ROLES.ROLE_RECOMMENDER_ADMIN, ROLES.ROLE_RECOMMENDER_HOTEL];
export const ROLES_SOLO = [ROLES.ROLE_RECOMMENDER_ADMIN, ROLES.ROLE_RECOMMENDER_SOLO];

export const ADMIN_ROLES = [ROLES.ADMIN, ROLES.ROLE_RECOMMENDER_ADMIN, ROLES.ROLE_SUPPLIER_ADMIN, ROLES.ROLE_RECOMMENDER_SOLO];
// tslint:disable-next-line:max-line-length
export const USER_ROLES = [ROLES.ROLE_RECOMMENDER_HOTEL, ROLES.ROLE_SUPPLIER_RESTAURANT, ROLES.ROLE_SUPPLIER_EVENT, ROLES.ROLE_SUPPLIER_TRANSPORT];

export const ROLES_SUPPLIER_RESTAURANT = [ROLES.ROLE_SUPPLIER_RESTAURANT, ROLES.ROLE_SUPPLIER_ADMIN];
export const ROLES_SUPPLIER_TRANSPORT = [ROLES.ROLE_SUPPLIER_ADMIN, ROLES.ROLE_SUPPLIER_TRANSPORT];
export const ROLES_SUPPLIER_EVENT = [ROLES.ROLE_SUPPLIER_ADMIN, ROLES.ROLE_SUPPLIER_EVENT];

export const ROLE_GROUP_ADMIN = [ROLES.ROLE_SUPPLIER_RESTAURANT, ROLES.ROLE_GROUP_ADMIN];

export const ROLE_GROUP_ADMIN_HOTEL = [ROLES.ROLE_RECOMMENDER_HOTEL, ROLES.ROLE_GROUP_ADMIN_HOTEL];
export const ROLE_SALES_DIRECTOR = [ROLES.SALES_DIRECTOR, ROLES.ROLE_RECOMMENDER_ADMIN];
export const ROLE_API_EXTERNAL_PARTNER = [ROLES.API_EXTERNAL_PARTNER, ROLES.ROLE_RECOMMENDER_ADMIN];

export const ROLE_SUPER_GROUP_ADMIN = [...ROLE_GROUP_ADMIN_HOTEL, ...ROLE_GROUP_ADMIN];

export class AdminRole {
    key: string | string[];
    value: string;
    GROUP: string;
    isChecked: boolean;
    label: string;
}
