import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, filter, catchError } from 'rxjs/operators';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (httpRequest.headers.get('exclude-interceptor') || httpRequest.url.includes('/public/') ) {
      return next.handle(httpRequest);
    }
    const authRequest = httpRequest.clone({
      setHeaders: {
        Authorization: 'Bearer ' + localStorage.getItem('pereToken')
      }
    });
    return next.handle(authRequest).pipe(
      filter(event => event instanceof HttpResponse),
      tap((event: HttpResponse<any>) => {
        if (event.headers.has('deprecated')) {
          console.warn('deprecated request! dont use!', event.headers.get('deprecated'));
          console.warn('deprecated - message: ', event.headers.get('deprecated-message'));
          console.warn('deprecated - use this API instead', event.headers.get('deprecated-new-api'));
        }
      }), catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
            reason: error && error.error && error.error.reason ? error.error.reason : '',
            status: error.status
        };
        // TODO: @Meme - open up ERROR dialogue window here with the given data above (error and reason from backend)
        console.error(data);
        return throwError(error);
    }));
  }
}
